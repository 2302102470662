// Pages
import VehicleList from "pages/VehicleList.js";
import Cars from "pages/LandingPages/Cars";

const routes = [
  {
    name: "Vehicle List",
    route: "/vehicle-list",
    component: <VehicleList />,
  },
  {
    name: "cars",
    route: "/cars",
    component: <Cars />,
  },
  {
    name: "cars",
    route: "/cars/:id",
    component: <Cars />,
  },
];

export default routes;
