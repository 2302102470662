// @mui material components
// import Icon from "@mui/material/Icon";

// @mui icons
// import GitHubIcon from "@mui/icons-material/GitHub";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import MailIcon from "@mui/icons-material/Mail";
// import CarRentalIcon from "@mui/icons-material/CarRental";
import DriveEtaIcon from "@mui/icons-material/DriveEta";

// Pages
import Home from "layouts/pages/landing-pages/home";
import AboutUs from "layouts/pages/landing-pages/about-us";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import VehicleList from "pages/VehicleList.js";
// import Author from "layouts/pages/landing-pages/author";
// import SignIn from "layouts/pages/authentication/sign-in";
// import Cars from "pages/LandingPages/Cars";

const routes = [
  {
    name: "home",
    icon: <HomeIcon />,
    route: "/",
    component: <Home />,
  },
  {
    name: "about",
    icon: <InfoIcon />,
    route: "/about-us",
    component: <AboutUs />,
  },
  {
    name: "contact us",
    route: "/contact-us",
    icon: <MailIcon />,
    component: <ContactUs />,
  },
  {
    name: "Buy Vehicle",
    icon: <DriveEtaIcon />,
    route: "/vehicle-list",
    component: <VehicleList />,
  },
  // {
  //   name: "vehicles",
  //   icon: <CarRentalIcon />,
  //   columns: 1,
  //   rowsPerColumn: 2,
  //   collapse: [
  //     {
  //       // name: "Vehicles",
  //       collapse: [
  //         {
  //           name: "Buy Vehicle",
  //           icon: <DriveEtaIcon />,
  //           route: "/vehicle-list",
  //           component: <VehicleList />,
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export default routes;
