import { useEffect } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import AOS from "aos";
import "aos/dist/aos.css";

import VehicleMainImage from "./VehicleMainImage";
import style from "./VehicleDetails.module.css";
// import AboutVehicle from "./AboutVehicle";
// import CarData from "./VehicleData";

export default function VehicleDetails({data}) {
 
  useEffect(() => {
    AOS.init({ duration: 1500 }); // Initialize AOS with options (adjust duration as needed)
  }, []);

  const vehicleBrand = data.data.attributes.vehicle_name
  const vehicleModel = data.data.attributes.model
  const vehicleImage = data.data.attributes.main

  return (
    <MKBox component="section" pt={3} pb={8}>
      <Container sx={{ paddingRight: 0, paddingLeft: 0 }}>
        <Grid container spacing={3} item>
          <Grid container justifyContent="center" alignItems="center" item pb={4}>
            <MKTypography variant="h1" className={style.heading}>{vehicleBrand} {vehicleModel}</MKTypography>
          </Grid>
        </Grid>
        <VehicleMainImage image={vehicleImage}/>
      </Container>
    </MKBox>
  );
}
