// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Components
import MKBox from "components/MKBox";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

// Routes
// import mainRoutes from "main.routes";

export default function VehicleListCard({data}) {

  return (
    <MKBox component="section" pt={{lg:8, xs:4}} pb={8}>
      <Container sx={{ paddingRight: 0, paddingLeft: 0 }}>
        <Grid container spacing={3}>
          {data.map((item) => (
            <Grid item sm={12} md={6} lg={4} pb={4} display="flex">
              <CenteredBlogCard
                action={{
                  type: "internal",
                  // route: "/cars/1",
                  route: `/cars/${item.id}`,
                  label: "Show Details",
                }}
                height="100%"
                vehicleDetails={item.attributes}
              />
            </Grid>
          ))}
          
        </Grid>
      </Container>
    </MKBox>
  );
}
