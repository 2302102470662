import { useEffect, useState } from "react";
// @mui material components
// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
// import MKBox from "components/MKBox";
// import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

// Animation
import AOS from "aos";
import "aos/dist/aos.css";

import VehicleListCard from "pages/VehicleList.js/sections/VehicleListCard";

// Gifs
import LoadingGif from "assets/gifs/car-loading-gif-16.gif"

// GraphQL
import { useQuery, gql } from '@apollo/client';

const GET_LATEST_VEHICLE_DETAILS = gql`
query {
  vehicleDetails(
    sort: "createdAt:desc"
    pagination: {
      limit: 3
    }
  ) {
    data {
      id
      attributes {
        vehicle_name
        model
        main {
          data {
            attributes {
              url
            }
          }
        }
        fuel
        distance
        price
      }
    }
  }
}
`;

export default function NewestVehicles() {

  const [list, setList] = useState([]);
  const { loading, error, data } = useQuery(GET_LATEST_VEHICLE_DETAILS);

  useEffect(() => {
    if(data && data.vehicleDetails) {
    setList(data.vehicleDetails.data)
  }
  }, [data])
  
  useEffect(() => {
    AOS.init({ duration: 2000 }); // Initialize AOS with options (adjust duration as needed)
  }, []);

  return (
    <>
    {/* // <MKBox component="section" pt={3} pb={8} data-aos="fade-up"> */}
      {/* <Grid container spacing={3}>
        <Grid item sm={12} md={6} lg={4} pb={4} display="flex" href="/pages/landing-pages/cars">
          <CenteredBlogCard
            image="https://images.unsplash.com/photo-1515049497350-e9dfc9527f5d?q=80&w=2075&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            category={{ color: "warning", label: "hub" }}
            title="Shared Coworking"
            description="Use border utilities to quickly style the border and border-radius of an element. Great for images,  buttons."
            author={{
              image: "https://bit.ly/3pZfIPh",
              name: "Mathew Glock",
              date: "Posted on 28 February",
            }}
            action={{
              type: "internal",
              route: "/cars",
              label: "Show Details ",
            }}
            height="100%"
          />
        </Grid>
        <Grid item sm={12} md={6} lg={4} pb={4}>
          <CenteredBlogCard
            image="https://images.unsplash.com/photo-1605816988069-b11383b50717?q=80&w=2088&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            category={{ color: "warning", label: "hub" }}
            title="Shared Coworking"
            description="Use border utilities to quickly style the border and border-radius of an element. Great for images,  buttons."
            author={{
              image: "https://bit.ly/3pZfIPh",
              name: "Mathew Glock",
              date: "Posted on 28 February",
            }}
            action={{
              type: "internal",
              route: "/somewhere",
              label: "Show Details",
            }}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={4} pb={4}>
          <CenteredBlogCard
            image="https://images.unsplash.com/photo-1605816988069-b11383b50717?q=80&w=2088&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            category={{ color: "warning", label: "hub" }}
            title="Shared Coworking"
            description="Use border utilities to quickly style the border and border-radius of an element. Great for images,  buttons."
            author={{
              image: "https://bit.ly/3pZfIPh",
              name: "Mathew Glock",
              date: "Posted on 28 February",
            }}
            action={{
              type: "internal",
              route: "/somewhere",
              label: "Show Details",
            }}
          />
        </Grid>
      </Grid> */}
      
      {error && 
          <Grid className="d-flex justify-content-center align-items-center h-100">
            Error
          </Grid>
        }
        { loading && 
          <Grid className="d-flex justify-content-center align-items-center h-100" lg={12}>
            <div>
              <img src={LoadingGif} alt="Loading.." />
            </div>
          </Grid>
        }
        {list && 
          <VehicleListCard data={list}/>
        }
    {/* </MKBox> */}
      </>
  );
}
